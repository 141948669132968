import React from "react";
import Input from '@material-ui/core/Input';



export default class Send extends React.Component{


    render(){

        return(

            <div>
            <Input type="file"/>
            
            </div>
    
    
    
        );

    } 


}